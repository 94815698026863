var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('CCard',[_c('CCardHeader',{attrs:{"color":"dark text-white"}},[_c('CRow',[_c('CCol',{staticClass:"d-flex",attrs:{"sm":"10"}},[_vm._v(_vm._s(_vm.titulo))]),_c('CCol',{staticClass:"d-flex justify-content-end",attrs:{"sm":"2"}},[_c('button',{staticClass:"close",attrs:{"type":"button","aria-label":"Close"},on:{"click":function($event){return _vm.closeCollapse()}}},[_vm._v("×")])])],1)],1),_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"sm":"11","lg":"10"}},[_c('CInput',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"horizontal":{ label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8'},"invalid-feedback":_vm.errorMessage(_vm.$v.rol.StowageRoleName),"is-valid":_vm.hasError(_vm.$v.rol.StowageRoleName),"label":_vm.$t('label.name'),"placeholder":_vm.$t('label.role')+' '+_vm.$t('label.name'),"addLabelClasses":"required text-right"},model:{value:(_vm.$v.rol.StowageRoleName.$model),callback:function ($$v) {_vm.$set(_vm.$v.rol.StowageRoleName, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.rol.StowageRoleName.$model"}})],1),_c('CCol',{staticClass:"col-sm-11 col-lg-2 center-field mr-0 px-1",staticStyle:{"text-align":"left"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                content: _vm.$t('label.add'),
                placement: 'top-end'
            }),expression:"{\n                content: $t('label.add'),\n                placement: 'top-end'\n            }"}],staticClass:"mr-1",attrs:{"color":"add","disabled":_vm.isSubmit,"size":"sm"},on:{"click":function($event){return _vm.submit()}}},[_c('CIcon',{attrs:{"name":"checkAlt"}})],1),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                content: _vm.$t('label.clearFields'),
                placement: 'top-end'
            }),expression:"{\n                content: $t('label.clearFields'),\n                placement: 'top-end'\n            }"}],attrs:{"color":"wipe","disabled":_vm.isSubmit,"size":"sm"},on:{"click":function($event){return _vm.refreshComponent()}}},[_c('CIcon',{attrs:{"name":"cil-brush-alt"}})],1)],1),_c('CCol',{attrs:{"sm":"11","lg":"10"}},[_c('CSelect',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"label":_vm.$t('label.applySigetMobile'),"horizontal":{label: 'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'},"add-label-classes":"text-right","options":_vm.selectOptionsFgApplyTablet,"value":_vm.rol.FgApplyTablet,"is-valid":_vm.hasError(_vm.$v.rol.FgApplyTablet),"invalid-feedback":_vm.errorMessage(_vm.$v.rol.FgApplyTablet)},on:{"update:value":function($event){return _vm.$set(_vm.rol, "FgApplyTablet", $event)}},model:{value:(_vm.rol.FgApplyTablet),callback:function ($$v) {_vm.$set(_vm.rol, "FgApplyTablet", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"rol.FgApplyTablet"}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }