<template>
  <div>
    <CCard>
      <CCardHeader color="dark text-white">
        <CRow>
          <CCol sm="10" class="d-flex ">{{titulo}}</CCol>
          <CCol sm="2" class="d-flex justify-content-end">
            <button type="button" aria-label="Close" class="close" @click="closeCollapse()">×</button>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
      <CRow>
          <CCol sm="11" lg="10">
            <CInput
              v-model.trim="$v.rol.StowageRoleName.$model"
              v-uppercase
              :horizontal="{ label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8'}"
              :invalid-feedback="errorMessage($v.rol.StowageRoleName)"
              :is-valid="hasError($v.rol.StowageRoleName)"
              :label="$t('label.name')"
              :placeholder="$t('label.role')+' '+$t('label.name')"
              addLabelClasses="required text-right"
            />
          </CCol>
          <CCol class="col-sm-11 col-lg-2 center-field mr-0 px-1" style="text-align: left;">
            <CButton color="add" :disabled="isSubmit" size="sm" class="mr-1"  
              @click="submit()"  
              v-c-tooltip="{
                  content: $t('label.add'),
                  placement: 'top-end'
              }"
              >
              <CIcon name="checkAlt"/>
            </CButton>
            <CButton color="wipe" :disabled="isSubmit" size="sm"  
              @click="refreshComponent()"  
              v-c-tooltip="{
                  content: $t('label.clearFields'),
                  placement: 'top-end'
              }"
              >
              <CIcon name="cil-brush-alt"/>
            </CButton>
          </CCol>
          <CCol sm="11" lg="10"> 
            <CSelect
              :label="$t('label.applySigetMobile')"
              :horizontal="{label: 'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
              add-label-classes="text-right"
              v-uppercase
              :options="selectOptionsFgApplyTablet"
              :value.sync="rol.FgApplyTablet"
              v-model.trim="rol.FgApplyTablet" 
              :is-valid="hasError($v.rol.FgApplyTablet)"
              :invalid-feedback="errorMessage($v.rol.FgApplyTablet)"             
            >
            </CSelect>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import SingleRoleFormValidation from '@/_validations/estiba/SingleRoleFormValidation';
import UpperCase  from '@/_validations/uppercase-directive';
import ModalMixin from '@/_mixins/modal';
import General from '@/_mixins/general';

import { DateFormater } from '@/_helpers/funciones';

//Data
function data() {
  return {
    isSubmit: false,
    Loading: false,
    rol: {
      StowageRoleId: '',
      StowageRoleName: '',
      FgApplyTablet: 1,
      Status: 1
    },
    stowageRoleName: '',
    selectOptionsFgApplyTablet: [
      { 
          value: 0, 
          label: 'NO'
      },
      { 
          value: 1, 
          label: this.$t('label.yes')
      }
    ],
  }
}

//Method
function submit() {
  try {
    this.Loading = true;
    this.isSubmit = true;
    this.$v.$touch();

    if (this.$v.$error) {
      this.Loading = false;
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }
     
    let metodo = this.edita ? 'PUT':'POST';
    let ruta = this.edita ? 'StowageRole-update' : 'StowageRole-insert'; 
    let StowageRoleJson = this.edita
      ? {
          StowageRoleId: this.rol.StowageRoleId,
          StowageRoleName: this.rol.StowageRoleName,
          FgApplyTablet: this.rol.FgApplyTablet,
          Status: this.rol.Status
        }
      : { 
          StowageRoleName: this.rol.StowageRoleName,
          FgApplyTablet: this.rol.FgApplyTablet,
        };

    this.$http.ejecutar(metodo, ruta, StowageRoleJson, { root: 'StowageRoleJson' })
    .then(response => {
      this.$notify({
        group: 'container',
        title: '¡Exito!',
        text: response.data.data[0].Response,
        type: "success"
      });
     //this.resetForm();
     this.refreshComponent();
    }).catch(err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).then(() => {
      this.isSubmit = false;
      this.Loading = false;
    });
  } catch (e) {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: "error"
    });
  }
}

function closeCollapse() {
  this.resetForm();
}

function resetForm() {
  this.rol.StowageRoleId = '';
  this.rol.StowageRoleName = '';
  this.rol.Status = 1;
  this.edita= false;
  this.rol.FgApplyTablet = 1;
  this.$nextTick(() => { this.$v.$reset() })
  this.$emit('close');
}
function setForm(newRole) {
  this.rol.StowageRoleId = newRole.StowageRoleId;
  this.rol.StowageRoleName = newRole.StowageRoleName;
  this.rol.FgApplyTablet = newRole.FgApplyTablet ? 1 : 0;
  this.rol.Status = newRole.FgActStowageRole ? 1 : 0;
}

function refreshComponent() {
  this.rol.StowageRoleId = '';
  this.rol.StowageRoleName = '';
  this.rol.Status = 1;
  this.rol.FgApplyTablet = 0;
  this.stowageRoleName= '',
  this.$nextTick(() => { this.$v.$reset() })
}

//Computed
function titulo() {
  return this.edita ? this.$t('label.edit')+' '+ this.$t('label.role')+': '+this.stowageRoleName : this.$t('label.nuevo')+' '+ this.$t('label.role');
}

function isSubmitValid(){
  return this.$v.$invalid || this.isSubmit;
}

export default {
  name:  'role-collapse',
  props: {
    StowageRole: {
      type: Object,
      default: () => {}
    },
    Edit: {
      type: Boolean,
      default: false
    },
    LimpiarCollapse: {
      type: Boolean,
      default: false
    },
  },
  data,
  mixins: [General, ModalMixin],
  validations: SingleRoleFormValidation,
  directives: UpperCase,
  methods: {
    submit,
    closeCollapse,
    resetForm,
    setForm,
    refreshComponent,
  },
  computed: {
    titulo,
    isSubmitValid,
  },
  watch: {
    Edit: function(newEdit){
      if(newEdit && Object.keys(this.StowageRole).length !== 0)
        this.setForm(this.StowageRole);  
        //this.resetForm(); 
    },
    StowageRole: function(newRole) {
      if(this.Edit && Object.keys(newRole).length !== 0)
        this.setForm(newRole);
       // this.resetForm(); 
    },
    LimpiarCollapse: function(val){
      if(val){
        this.refreshComponent();
      }
    }
  }
}
</script>

<style lang="scss">
  .card-body .card-header {
    background-color: #5D6164 !important;
    color: #fff;
  }
  .center-cell {
  text-align: center;
  }
  .table-index {
    table {
      td {
        vertical-align: middle !important;
      }
    }
  }
</style>